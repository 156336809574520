import modulous from '@huffpost/modulous';

const placements = [
  'asset_inserts',
  'bottom_1x1',
  'leaderboard_flex',
  'mobileweb_assets',
  'mobileweb_instream_banner',
  'mobileweb_top',
  'repeating_dynamic_display',
  'right_rail_flex',
  'right_rail_recirc',
  'top_1x1',
].join(',');

const placementsNoAds = [
  'asset_inserts',
  'bottom_1x1',
  'mobileweb_assets',
  'right_rail_recirc',
  'top_1x1',
].join(',');


async function makeCall({ site, noAds = false }) {
  let resp = {};
  try {
    resp = await modulous.get({
      query: {
        site: site,
        category: 'horoscopes',
        platform: 'projects-responsive',
        placements: noAds ? placementsNoAds : placements, 
        // test_ad: 'true',
      },
      env: 'production',
    });
  } catch ( error ) {
    console.error(error)
  }
  return resp;
}

export async function getAds({
  placements = ['repeating_dynamic_display', 'leaderboard_flex'],
  site,
  noAds, 
}){
  const payload = await makeCall({ site, noAds });
  const adsByPlacement = {
    head: payload.head,
  };
  placements.forEach(placement => {
    adsByPlacement[placement] = payload?.flights?.[placement]?.payload || '';
  });
  return adsByPlacement;
}
